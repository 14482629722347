
    <template>
      <section class="content element-doc">
        <h2>Icon 图标</h2>
<p>提供了一套常用的图标集合。</p>
<h3>使用方法</h3>
<p>直接通过设置类名为 <code>el-icon-iconName</code> 来使用即可。例如：</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;i class=&quot;el-icon-edit&quot;&gt;&lt;/i&gt;
&lt;i class=&quot;el-icon-share&quot;&gt;&lt;/i&gt;
&lt;i class=&quot;el-icon-delete&quot;&gt;&lt;/i&gt;
&lt;el-button type=&quot;primary&quot; icon=&quot;el-icon-search&quot;&gt;搜索&lt;/el-button&gt;
</code></pre></template></demo-block><h3>图标集合</h3>
<ul class="icon-list">
  <li v-for="name in $icon" :key="name">
    <span>
      <i :class="'el-icon-' + name"></i>
      <span class="icon-name">{{'el-icon-' + name}}</span>
    </span>
  </li>
</ul>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("i", { class: "el-icon-edit" }),
    _createVNode("i", { class: "el-icon-share" }),
    _createVNode("i", { class: "el-icon-delete" }),
    _createVNode(_component_el_button, {
      type: "primary",
      icon: "el-icon-search"
    }, {
      default: _withCtx(() => [
        _createTextVNode("搜索")
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  